<template>
  <div id="queues">
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar"
      >
        {{ snackmsg }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-container>
      <v-card class="mb-3">
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-card-text class="pb-6">
          <v-form v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.member_id"
                  label="RMN ID"
                  :rules="noEmptyField"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.first_name"
                  label="First Name"
                  :rules="noEmptyField"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.last_name"
                  label="Last Name"
                  :rules="noEmptyField"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="user.role"
                  label="Role"
                  :items="roles"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-row justify-center">
              <v-btn
                :disabled="!valid"
                elevation="2"
                @click="save"
                class="mr-1"
              >
                Submit
              </v-btn>
              <v-btn
                elevation="2"
                @click="reset"
                class="ml-1"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Current Users</v-card-title>
        <v-card-subtitle>Click on a user to edit</v-card-subtitle>
        <v-card-text>
          <div v-if="usersList.length === 0">
            Nothing found
          </div>
          <div v-if="usersList.length > 0">
            <v-data-table
              :items="usersList"
              :headers="headers"
              @click:row="rowClick"
              dense
            >
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "UserManagement",
  computed: {
		...mapGetters({
			authUser: 'auth/user',
			usersList: 'users/list'
		}),
    formTitle() {
      if (this.editId > 0) {
        return `Editing Member: ${this.user.first_name} ${this.user.last_name}`
      }

      return 'Add New Member'
    }
  },
  data () {
		return {
      valid: false,
      editId: 0,
			expanded: [],
      roles: [
        { value: "USER", text: "User" },
        { value: "ADMIN", text: "Admin" }
      ],
      user: {
        member_id: '',
        first_name: '',
        last_name: '',
        role: "USER"
      },
      noEmptyField: [
        v => !!v || "This field must be filled"
      ],
			headers: [
				{ text: 'RMN ID', value: 'member_id' },
				{ text: 'First Name', value: 'first_name' },
				{ text: 'Last Name', value: 'last_name' },
				{ text: 'Role', value: 'role' },
			],
      snackbar: false,
      snackmsg: ''
		}
	},
  methods: {
    save() {
      let test = new this.$FeathersVuex.api.User({...this.user})
      test.save()
        .then(() => {
          const vm = this;
          this.snackmsg = "User saved";
          this.snackbar = true;
          setTimeout(() => { vm.snackbar = false; }, 5000)
        })
        .catch((err) => {
          console.error(`Error saving user: ${err}`)
        })
    },
    reset() {
      this.user = { 
        member_id: '',
        first_name: '',
        last_name: '',
        role: 'USER'
      }
      this.editId = 0;
    },
    rowClick (evt) {
      this.editId = parseInt(evt.id);
      this.user.id = parseInt(evt.id);
      this.user.first_name = evt.first_name;
      this.user.last_name = evt.last_name;
      this.user.member_id = evt.member_id;
      this.user.role = evt.role;
    }
  }
}
</script>